@import "../../../styles/base/abstracts";

$border-width-thick: 4px;

.hbs-byline {
  display: flex;

  &.hbs-byline--headshots {
    flex-direction: row-reverse;
    align-items: center;

    @include mq($bp-tablet) {
      flex-direction: row;
    }
  }

  .hbs-event-agenda-item__info-speakers & {
    display: block;

    .hbs-byline__text {
      margin-top: 0;
    }
  }

  time {
    display: inline-block;
  }
}

.hbs-byline__text {
  max-width: 30em;
}

.hbs-byline__author {
  display: inline;

  a {
    @include link;
  }
}

.hbs-byline__thumbnails {
  vertical-align: middle;
  display: inline-flex;
  margin-left: $spacing-sm;
  margin-right: -$border-width-thick;
  margin-bottom: $spacing-xs;

  @include mq($bp-tablet) {
    margin-right: $spacing-sm;
    margin-left: -$border-width-thick;
  }
}

.hbs-byline__asset {
  @include size(3em);
  border: $border-width-thick solid $c-bg;
  border-radius: 50%;
  overflow: hidden;

  @include mq($bp-tablet) {
    @include size(4em);
  }

  * {
    @include size(100%);
    object-fit: cover;
  }
}
